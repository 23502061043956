(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";function _interopRequireDefault(e){return e&&e.__esModule?e:{default:e}}var _src=require("./src"),_src2=_interopRequireDefault(_src);window.FixWheel=_src2.default;

},{"./src":5}],2:[function(require,module,exports){
var detectBrowser=require("./lib/detectBrowser"),agent;"undefined"!=typeof navigator&&navigator&&(agent=navigator.userAgent),module.exports=detectBrowser(agent);
},{"./lib/detectBrowser":3}],3:[function(require,module,exports){
module.exports=function(e){return e?[["edge",/Edge\/([0-9\._]+)/],["yandexbrowser",/YaBrowser\/([0-9\._]+)/],["chrome",/(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/],["crios",/CriOS\/([0-9\.]+)(:?\s|$)/],["firefox",/Firefox\/([0-9\.]+)(?:\s|$)/],["opera",/Opera\/([0-9\.]+)(?:\s|$)/],["opera",/OPR\/([0-9\.]+)(:?\s|$)$/],["ie",/Trident\/7\.0.*rv\:([0-9\.]+)\).*Gecko$/],["ie",/MSIE\s([0-9\.]+);.*Trident\/[4-7].0/],["ie",/MSIE\s(7\.0)/],["bb10",/BB10;\sTouch.*Version\/([0-9\.]+)/],["android",/Android\s([0-9\.]+)/],["ios",/Version\/([0-9\._]+).*Mobile.*Safari.*/],["safari",/Version\/([0-9\._]+).*Safari/]].map(function(r){if(r[1].test(e)){var i=r[1].exec(e),o=i&&i[1].split(/[._]/).slice(0,3);return o&&o.length<3&&Array.prototype.push.apply(o,1==o.length?[0,0]:[0]),{name:r[0],version:o.join(".")}}}).filter(Boolean).shift():null};
},{}],4:[function(require,module,exports){
"use strict";function _classCallCheck(e,t){if(!(e instanceof t))throw new TypeError("Cannot call a class as a function")}Object.defineProperty(exports,"__esModule",{value:!0});var _createClass=function(){function e(e,t){for(var i=0;i<t.length;i++){var r=t[i];r.enumerable=r.enumerable||!1,r.configurable=!0,"value"in r&&(r.writable=!0),Object.defineProperty(e,r.key,r)}}return function(t,i,r){return i&&e(t.prototype,i),r&&e(t,r),t}}(),safariChecker=require("./safari-checker"),FixWheel=function(){function e(t){_classCallCheck(this,e),this.eventName=t,this.fixWheel=this.fixWheel.bind(this),this.checkOverflow=this.checkOverflow.bind(this)}return _createClass(e,[{key:"init",value:function(e){var t=arguments.length>1&&void 0!==arguments[1]&&arguments[1];return(safariChecker.hasBadMouseBehavior()||t)&&(this.rootNode=e,this.rootNode.addEventListener(this.eventName,this.fixWheel,!1),this.isFixed=!0),this.isFixed}},{key:"destroy",value:function(){return this.isFixed&&(this.rootNode.removeEventListener(this.eventName,this.fixWheel),this.isFixed=!1),this.isFixed}},{key:"preventDefault",value:function(e){e.preventDefault()}},{key:"checkOverflow",value:function(e,t){var i=e.clientHeight,r=e.scrollHeight,o=e.scrollTop,n=window.getComputedStyle(e);if(("scroll"===n.overflowY||"auto"===n.overflowY)&&i<r){var l=i+o>=r,s=0===o;if(t>0&&!l||t<0&&!s)return e;if(0===t)return null}return e.parentNode?e.parentNode===this.rootNode?this.rootNode:this.checkOverflow(e.parentNode,t):null}},{key:"fixWheel",value:function(e){this.preventDefault(e);var t=this.checkOverflow(e.target,e.deltaY);t&&(t.scrollTop+=e.deltaY)}}]),e}();exports.default=FixWheel;

},{"./safari-checker":6}],5:[function(require,module,exports){
"use strict";function _interopRequireDefault(e){return e&&e.__esModule?e:{default:e}}Object.defineProperty(exports,"__esModule",{value:!0});var _fixWheel=require("./fix-wheel"),_fixWheel2=_interopRequireDefault(_fixWheel);exports.default=_fixWheel2.default;

},{"./fix-wheel":4}],6:[function(require,module,exports){
"use strict";function _interopRequireDefault(r){return r&&r.__esModule?r:{default:r}}function majorVersion(){if(!browserVersion)return null;var r=browserVersion.split(".")[0];return parseInt(r,10)}function isSafari(){return!!browserName&&"safari"===browserName}function isSafari9(){return isSafari()&&9===majorVersion()}function isSafari10(){return isSafari()&&10===majorVersion()}var _detectBrowser=require("detect-browser"),_detectBrowser2=_interopRequireDefault(_detectBrowser),browserName=_detectBrowser2.default&&_detectBrowser2.default.name,browserVersion=_detectBrowser2.default&&_detectBrowser2.default.version;exports.hasBadMouseBehavior=function(){return isSafari9()||isSafari10()};

},{"detect-browser":2}]},{},[1]);
